import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import './AuthMethods.css';
import google_logo from '../../assets/icons/google-logo-1.png';
import apple_logo from '../../assets/icons/apple-logo-1.png';
import VerificationCodeInput from '../VerificationCodeInput/VerificationCodeInput';
import Loader from '../Common/Loader/Loader';
import { useModal } from '../../context/ModalContext';
import TestLoginButton from '../AuthButton/TestLoginButton/TestLoginButton'; // Импортируем новую кнопку

const AuthMethods = ({ providers = [], addToken }) => {
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2000';
    const isProduction = process.env.GLOBAL_ENVIROMENT === 'production';
    const [email, setEmail] = useState('');
    const [showVerification, setShowVerification] = useState(false);
    const [fingerprint, setFingerprint] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resetCodeFields, setResetCodeFields] = useState(false);
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const { showModal } = useModal();

    useEffect(() => {
        const fpPromise = FingerprintJS.load();
        fpPromise
            .then((fp) => fp.get())
            .then((result) => {
                const visitorId = result.visitorId;
                setFingerprint(visitorId);
            });
    }, []);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleAuth = (provider) => {
        let authUrl = `${API_URL}/api/v1/auth/${provider}?client=web&fingerprint=${fingerprint}`;
        if (addToken) {
            const token = localStorage.getItem('accessToken');
            authUrl += `&token=${token}`;
        }
        window.location.href = authUrl;
    };

    const handleEmailLogin = async () => {
        if (!isValidEmail(email)) {
            showModal(t('AuthMethods_error.title'), <p>{t('AuthMethods_error.invalid_email')}</p>, { closeButtonType: 'button' });
            return;
        }

        setIsEmailLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/v1/auth/email/init`, { email });
            if (response.status === 200) {
                setShowVerification(true);
            }
        } catch (error) {
            console.error('Error initiating email login:', error);
            showModal(t('AuthMethods_error.title'), <p>{t('AuthMethods_error.invalid_email')}</p>, { closeButtonType: 'button' });
        } finally {
            setIsEmailLoading(false);
        }
    };

    const handleVerifyCode = async (code) => {
        setIsLoading(true);
        try {
            const queryParams = new URLSearchParams({
                fingerprint
            });

            let authUrl = `${API_URL}/api/v1/auth/email?${queryParams.toString()}`;

            if (addToken) {
                const token = localStorage.getItem('accessToken');
                authUrl += `&token=${token}`;
            }
            const response = await axios.post(authUrl, { email, code });

            const { access, refresh } = response.data;
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
            window.location.href = '/profile';
        } catch (error) {
            let message = t('AuthMethods_error.invalid_code');
            if (error.response && error.response.status) {
                message = error.response.status + ": " + handleAuthError(error.response.status);
            }
            alert(message);
            setResetCodeFields(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAuthError = (status) => {
        switch (status) {
            case 409:
                return t('AuthMethods_error.auth_conflict');
            case 406:
                return t('AuthMethods_error.auth_used');
            case 404:
                return t('AuthMethods_error.auth_not_found');
            case 401:
                return t('AuthMethods_error.auth_access');
            case 500:
                return t('AuthMethods_error.unknown');
            default:
                return null;
        }
    };

    const handleResetComplete = () => {
        setResetCodeFields(false);
    };

    const handleGoBack = () => {
        setShowVerification(false);
        setEmail('');
    };

    if (showVerification) {
        return (
            <VerificationCodeInput
                email={email}
                onVerifyCode={handleVerifyCode}
                isLoading={isLoading}
                resetCodeFields={resetCodeFields}
                onResetComplete={handleResetComplete}
                onGoBack={handleGoBack}
                handleResendCode={handleEmailLogin}
            />
        );
    }

    return (
        <div className="auth-methods-container">
            <img src="/logo.png" alt="Logo" className="logo" />
            <h1 className="title">{t('AuthMethods_welcome_title')}</h1>
            {!providers.includes('google') && (
                <button
                    onClick={() => handleAuth('google')}
                    className="auth-button google-button button1"
                >
                    <img src={google_logo} alt="Google" />
                    {t('AuthMethods_sign_in_google')}
                </button>
            )}
            {!providers.includes('apple') && (
                <button
                    onClick={() => handleAuth('apple')}
                    className="auth-button apple-button button1"
                >
                    <img src={apple_logo} alt="Apple" />
                    {t('AuthMethods_sign_in_apple')}
                </button>
            )}
            {!isProduction && !addToken && <TestLoginButton />}

            {(!providers.includes('email') && (!providers.includes('apple') || !providers.includes('google'))) && (
                <div className="separator">{t('AuthMethods_or')}</div>
            )}

            {!providers.includes('email') && (
                <>
                    <input
                        type="email"
                        placeholder={t('AuthMethods_email_placeholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input1 input"
                    />

                    <button
                        onClick={handleEmailLogin}
                        className="auth-button email-button button1"
                        disabled={isEmailLoading}
                    >
                        {t('AuthMethods_sign_in_email')}
                    </button>
                </>
            )}
            {isEmailLoading && <Loader />}

            {/* Footer */}
            <div className="auth-footer">
                <p>
                    {t('AuthMethods_footer_text') + " "}

                    <a href="https://milevstudio.com/studyge-terms-and-conditions/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '5px' }}>
                        {t('AuthMethods_terms_of_use')}
                    </a>
                    {t('AuthMethods_and') + " "}
                    <a href="https://milevstudio.com/studyge-privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
                        {t('AuthMethods_privacy_policy')}
                    </a>
                    {t('AuthMethods_footer_text_end')}
                </p>
            </div>
        </div>
    );
};

export default AuthMethods;
